<script setup>
import {onMounted, ref} from "vue";
import {Map, Popup} from "maplibre-gl";
import MapLegend from "./MapLegend.vue";
import {GeoDataManager} from '../../utils/geoDataManager.ts'
import {datapoolToObjectKeyValueArray, getBoundsFromGeoJson} from "../../utils/mapHelper.ts";
import {useTooltipStore} from "../../stores/pinia/tooltipStore.ts";

const tooltipStore = useTooltipStore()

const geoDataManager = new GeoDataManager();

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  dataName: {
    type: String,
    required: true
  },
  connectors: {
    type: Object,
    required: true
  },
  matchSettings: {
    type: Object,
    required: true
  },
  colorScale: {
    type: Array,
    required: false,
    default: () => {
      return ['#ef895b', '#ec9670', '#e9a383', '#e7b096', '#e4bca9', '#e7c8b9', '#f1d3c2'];
    }
  },
  useMinMaxColorScale: {
    type: Boolean,
    required: false,
    default: false
  },
  showLegend: {
    type: Boolean,
    required: false,
    default: true
  },
  dexieKey: {
    type: String,
    required: true
  },
  jwt: {
    type: String,
    required: true
  },
  mapZoom: {
    type: Number,
    required: false,
    default: 5.7
  },
  smoothLegend: {
    type: Boolean,
    required: false,
    default: false
  },
  legendValueFormatter: {
    type: Function,
    required: false,
    default: (value) => {
      return value;
    }
  },
  fitBounds: {
    type: Boolean,
    required: false,
    default: false
  },
  showMap: {
    type: Boolean,
    required: false,
    default: false
  }
})


const matchSettings = ref(props.matchSettings);

const mapContainerId = ref('map-' + Math.random().toString(36).substr(2, 9));
const map = ref(null);

const colorScaleRef = ref(props.colorScale || ['#ef895b', '#ec9670', '#e9a383', '#e7b096', '#e4bca9', '#e7c8b9', '#f1d3c2']);


const min = ref(0);
const max = ref(0);
const hoverValue = ref(0);
const hover = ref(false);

const componentTitle = ref(null)

onMounted(() => {
  if (mapContainerId.value === null) return;

  //check if container is findable, if not, wait a bit
  if (document.getElementById(mapContainerId.value) === null) {
    setTimeout(() => {
      if (document.getElementById(mapContainerId.value) === null) {
        console.warn('Map container not found, maybe it is not ready yet?', mapContainerId.value)
        return;
      }
    }, 1000)
  }


  //setup tooltip
  tooltipStore.setupTooltipDom(componentTitle.value, props.id)

  //Load initial map
  loadMapWithData(props.connectors)

  //listen to mapDataChanged, if so reload map
  window.addEventListener('mapDataChanged' + props.id, (e) => {
    map.value.remove();
    loadMapWithData(e.detail)
  })

});

const formattedTitle = ref('');

/**
 * Load map with data
 *
 * @param connector Highcharts connector
 */
const loadMapWithData = (connector) => {


  //replace %modifier% with modifier that is active
  if(props.title.includes('%modifier%')) {
    const min = connector.table.modifier.options.ranges[0].minValue;
    const max = connector.table.modifier.options.ranges[0].maxValue;

    if(min === max) {
      formattedTitle.value = props.title.replace('%modifier%', min);
    } else {
      formattedTitle.value = props.title.replace('%modifier%', min + ' - ' + max);
    }

  } else {
    formattedTitle.value = props.title;
  }

  if (props.dexieKey.includes("GM")) {
    //Check if geojson is already in dexie
    geoDataManager.checkKeyExists(props.dexieKey).then((exists) => {
      if (!exists) {
        //If not, fetch geojson from api
        geoDataManager.addGeoDataBuurten(props.dexieKey).then(() => {
          //And load map
          loadMapWithData(connector);
        })
      } //else continue
    })
  }

  geoDataManager.getData(props.dexieKey).then((topo) => {
    if (topo === null) return;
    if (mapContainerId.value === null) return;

    //wait 2 seconds before loading map
    setTimeout(() => {

      map.value = new Map({
        container: mapContainerId.value,
        // style: 'empty.json', // style URL //TODO: Get style from Xitres Style Service (vps13 ofzo)
        style: props.showMap ? 'https://maps2.nbo.nl/styles/xitres-bright-light/style.json' : 'https://maps2.nbo.nl/styles/xitres-empty/style.json',
        center: [5.2913, 52.1326], // starting position [lng, lat]
        zoom: props.mapZoom // starting zoom
      });

      //disable zooming
      map.value.scrollZoom.disable();

      //disable moving map with mouse and any other interaction
      map.value.dragPan.disable();
      map.value.touchZoomRotate.disableRotation();
      map.value.doubleClickZoom.disable();
      map.value.touchPitch.disable();
      map.value.keyboard.disable();
      //disable 3d
      map.value.boxZoom.disable();
      map.value.dragRotate.disable();

      //After fetching data we can add the source and layer

      map.value.on('load', () => {

        let data;

        //Highcharts Datamodifiers werken door enkele values te verwijderen, ons eigen systeem ziet ze wel dus wij moeten ze zelf filteren
        if (connector.table.modifier) {
          if (connector.table.modifier.options.type !== "Range") {
            throw new Error("Only range modifier is supported for choropleth maps");
          }

          data = datapoolToObjectKeyValueArray(connector.table.columns, connector.table.modifier.options.ranges[0].column, connector.table.modifier.options.ranges[0].minValue);

        } else {
          data = datapoolToObjectKeyValueArray(connector.table.columns);
        }

        //Get min and max value (for legend and color scale)
        const values = connector.table.columns[matchSettings.value.valueRow];

        // Fallback voor dirty input data
        values.forEach((value, index) => {
          if (typeof value === 'string') {
            values[index] = parseFloat(value) || 0;
          }
        });


        // Stel dat 'values' je array van getallen is
        let minVal = Math.min(...values);
        let maxVal = Math.max(...values);

        // Afronden naar het dichtstbijzijnde tiental
        minVal = Math.round(minVal / 10) * 10;
        maxVal = Math.round(maxVal / 10) * 10;

        // Als de afgeronde waarde kleiner is dan de oorspronkelijke, trek dan 10 af van minVal
        if (minVal > Math.min(...values)) {
          minVal -= 10;
        }

        // Als de afgeronde waarde groter is dan de oorspronkelijke, tel dan 10 op bij maxVal
        if (maxVal < Math.max(...values)) {
          maxVal += 10;
        }

        // Stel de min en max in
        min.value = minVal;
        max.value = maxVal;

        if (props.useMinMaxColorScale) {
          const maxColors = ['#f1d3c2', '#e7c8b9', '#e4bca9', '#e7b096', '#e9a383', '#ec9670'];
          const minColors = ['#d5f6ff', '#bedee3', '#a6d5db', '#89c7cf', '#6cb9c3', '#4eaeb8'].reverse();

          //All values below 0 should be blue (minColors)
          //All values above 0 should be red (maxColors)
          //We max out at 7 colors

          //If we only have values belows 0 the legend is blue and vice versa

         if(min.value < 0 && max.value < 0) {
           //All values are below 0
           colorScaleRef.value = minColors;
         } else if(min.value > 0 && max.value > 0) {
           //All values are above 0
           colorScaleRef.value = maxColors;
         } else {
           //We have values above and below 0
           //We need to calculate the amount of colors for each side
           const maxColorsAmount = Math.round(max.value / (max.value + Math.abs(min.value)) * 6);
           const minColorsAmount = 6 - maxColorsAmount;

           //Now we need to create the color scale
           const minColorStep = Math.round(minColors.length / minColorsAmount);
           const maxColorStep = Math.round(maxColors.length / maxColorsAmount);

           //Create the color scale
           const colorScale = [];
           for(let i = 0; i < minColorsAmount; i++) {
             colorScale.push(minColors[i * minColorStep]);
           }
           for(let i = 0; i < maxColorsAmount; i++) {
             colorScale.push(maxColors[i * maxColorStep]);
           }

           colorScaleRef.value = colorScale;
         }


        }


        //Stops when to show which color
        let stops = [];
        colorScaleRef.value.forEach((color, index) => {
          //calculate value (based on amount of colors)
          const value = min.value + (max.value - min.value) / colorScaleRef.value.length * index;
          //Add it to the stops array (according to maplibre style spec)
          stops.push(value);
          stops.push(color);
        });

        if (topo.features === undefined || topo.features.length === 0) {
          if (import.meta.env.VITE_APP_DEBUG === 'true') {
            console.error('ChoroMap Critical Warning (Debug mode)\nNo features found in topojson for chart: ' + props.title + "\nMapChoropleth.vue:223");
          }
          //No features found, so we can't do anything (data is probably not ready yet)
          return
        }

        //add value as property to topo (so we can access it in the layer etc)
        topo.features.forEach(feature => {
          const find = data.find(d => d[matchSettings.value.poolKey] === feature.properties[matchSettings.value.topoKey]);
          if (!find) return;

          let val;
          const rawColumn = find[matchSettings.value.valueRow];
          if (rawColumn === undefined || rawColumn === null || rawColumn === '' && !parseInt(rawColumn)) {
            val = 0;
          } else {
            if (typeof rawColumn === 'number') {
              val = rawColumn;
            } else {
              val = parseInt(rawColumn);
            }
          }
          feature.properties['value'] = val;
        });

        if (import.meta.env.VITE_APP_DEBUG === 'true') {
          //We all make mistakes :-(
          if (topo.features.every(feature => feature.properties['value'] === topo.features[0].properties['value'])) {
            console.error('ChoroMap Critical Warning (Debug mode)\nYou might have swapped poolKey and topoKey for chart: ' + props.title + "\nI know this because all values are the same. This means that the topoKey is fConnnot found in the data.");
          }

          //Check if all features have a value
          if (topo.features.some(feature => feature.properties['value'] === undefined)) {
            //Log the features that have no value
            const find = topo.features.find(feature => feature.properties['value'] === undefined);
            const location = find.properties[matchSettings.value.locationRow];
            const poolKey = find.properties[matchSettings.value.topoKey];
            console.warn('ChoroMap Information Warning (Debug mode)\n The following feature has no value: ' + location + ' (' + poolKey + ') \n Map: [' + props.title + ']');
          }
        }

        //remove all topo items that have no value
        // topo.features = topo.features.filter(feature => feature.properties['value'] !== undefined);


        // Add source and layer
        map.value.addSource('sourceDataApi', {
          type: 'geojson',
          data: topo
        });

        map.value.addLayer({
          id: 'layerDataApi',
          source: 'sourceDataApi',
          type: 'fill',
          paint: {
            'fill-color': ['step', ['get', 'value'], '#cee6ea', ...stops], // '#ccc' is de standaardkleur
            'fill-opacity': 0.6,
            'fill-outline-color': '#8a8a85',
          }
        });

        //fit bounds?
        if (props.fitBounds) {
          const bounds = getBoundsFromGeoJson(topo);
          map.value.fitBounds(bounds, {
            padding: 20
          });
        }


        const popup = new Popup({
          closeButton: false,
          closeOnClick: false
        });


        //show gemeenten on hover
        map.value.on('mousemove', (e) => {
          const features = map.value.queryRenderedFeatures(e.point, {
            layers: ['layerDataApi']
          });

          if (!features.length) {
            hover.value = false;
            map.value.getCanvas().style.cursor = '';
            popup.remove();
            return;
          }

          const feature = features[0];
          if (feature.properties['value'] === undefined) {
            //show no data popup
            popup.setLngLat(e.lngLat).setHTML(`
              <div class="choro-popup">
                <h6 class='popup-title'>${feature.properties[props.matchSettings["locationRow"]]}</h6>
                <span class="popup-key">Geen data beschikbaar</span>
              </div>
            `).addTo(map.value);
          }

          hoverValue.value = feature.properties['value'];
          hover.value = true;

          const prop = data.find(d => d[matchSettings.value.topoKey] === feature.properties[matchSettings.value.poolKey])
          if (!prop) return;

          popup.setLngLat(e.lngLat).setHTML(`
            <div class="choro-popup">
              <h6 class='popup-title'>${feature.properties[props.matchSettings["locationRow"]]}</h6>
              <span class="popup-key">${props.dataName}:</span> <span class="popup-value">${feature.properties['value']}</span>
            </div>
          `).addTo(map.value);
          //set cursor to cursor-pointer
          map.value.getCanvas().style.cursor = 'pointer';

        })

      });

    }, 10);
  })
}


</script>

<template>
  <div class="map-container-dashboard highcharts-dashboards-component px-4 h-100">
    <h2 class="highcharts-dashboards-component-title flex justify-between items-center w-full" ref="componentTitle">
      {{ formattedTitle }}
    </h2>

    <div :id="mapContainerId" class="map choropleth-map"></div>

    <div class="max-width" v-if="showLegend">
      <map-legend :data-name="dataName" :color-scale="colorScaleRef" :min="min" :max="max" :hover="hover"
                  :hover-value="hoverValue" :smooth-scale="props.smoothLegend"
                  :legend-value-formatter="props.legendValueFormatter"></map-legend>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 80%;
}

.max-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.map-container-dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>
