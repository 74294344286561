<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {AccountStore} from "../../stores/accountStore.ts";
import {Fetcher, FetcherResponse} from "../../utils/fetcher.ts";
import router from "../../router";
import MainEnvironment from "../../components/layouts/MainEnvironment.vue";

const fetcher = new Fetcher();

const username = ref('');
const password = ref('');

const isIncorrect = ref(false);
const isExpired = ref(false);

const errorTitle = ref('Uw inloggegevens zijn onjuist');
const errorText = ref('Probeer het opnieuw.');

const showEnvLogin = import.meta.env.VITE_APP_ENABLE_ENV_LOGIN === 'true';

onMounted(() => {
  // Get the redirect URL from the query string
  const url = new URL(window.location.href);

  //if ?expired=true is in the url, show the message that the token is expired
  if (url.searchParams.get('expired') === 'true') {
    isExpired.value = true;
  }

  //if ?changePassword=true is in the url, show the message that the user needs to change the password
  if (url.searchParams.get('changePassword') === 'true') {
    router.push('/auth/create-password');
  }

});

const loginWithEnv = () => {
  username.value = import.meta.env.VITE_APP_USERNAME;
  password.value = import.meta.env.VITE_APP_PASSWORD;
  handleSubmit();
}

async function handleSubmit() {
  const result: FetcherResponse<any> = await fetcher.post('/auth/login', {
    username: username.value,
    password: password.value
  })

  if (result.ok) {
    //Set the token and username in the AccountStore
    AccountStore.token = result.data.token;
    AccountStore.username = result.data.username;

    await router.push("/woningmarkt");

  } else {
    isIncorrect.value = true;
    errorTitle.value = result.data.msg;
    errorText.value = result.data.info;
  }

}
</script>

<template>
  <MainEnvironment>
    <div class="flex items-center justify-center">
      <div class="bg-white rounded-lg shadow-md lg:flex">
        <div class="px-8 md:w-128 mt-8">
          <h1 class="text-xl font-bold">Welkom bij het WoningbouwersNL Data Dashboard</h1>
          <h2 class="mb-4">Meten is weten - Zeker in nieuwbouw</h2>
          <p class="text-gray-600 mb-4">Ben je benieuwd naar de ontwikkelkansen in een bepaalde wijk, wil je meer weten
            over de woonwensen van woningzoekenden in een bepaalde gemeente of hoeveel mensen zich hebben ingeschreven
            voor een bepaald project?
            Duik in dit dashboard en vind antwoorden op vragen over de woningvoorraad, de sociaal economische structuur,
            demografische ontwikkeling, woonwensen en meer indicatoren per wijk, buurt of gemeente!</p>

          <!--                <router-link to="/register" class="w-full p-2 bg-wbnl-orange hover:bg-wbnl-orange-light rounded-md text-white font-bold">Account aanvragen</router-link>-->
          <p><a
              href="mailto:data@xitres.nl;info@woningbouwersnl.nl?subject=Aanvraag%20WoningbouwersNL%20Data%20Dashboard%20accountgegevens&body=Beste%2C%0A%0AHierbij%20wil%20ik%20inloggegevens%20aanvragen%20voor%20toegang%20tot%20het%20WoningbouwersNL%20Data%20Dashboard.%0A%0ANaam%0AAchternaam%0AE-mail%0ABedrijf%0A06-%20nummer%20%0A%0AMet%20vriendelijke%20groet%2C%0A%0A"
              class="w-full py-2 px-8 bg-wbnl-orange hover:bg-wbnl-orange-light rounded-md text-white font-bold">
            Account aanvragen
          </a></p>
        </div>
        <div class="lg:w-96 p-8 lg:border-s border-gray-200">
          <img src="../../assets/woningbouwers_logo.svg" alt="logo" class="w-[50%] hidden lg:block">
          <hr class="mb-3 border-b-2 border-gray-200 lg:hidden">

          <!-- Under LG thick border -->

          <!-- Info if password is incorrect -->
          <div v-if="isIncorrect" class="my-4 bg-red-500 p-3 rounded">
            <p class="text-sm text-white mb-1">{{ errorTitle }}</p>
            <p class="text-xs text-white">{{ errorText }}</p>
          </div>
          <!-- Info if token is expired -->
          <div v-if="isExpired" class="my-4 bg-amber-500 p-3 rounded font-medium">
            <!-- Close button -->
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="isExpired = false">
              <span aria-hidden="true">&times;</span>
            </button>
            <p class="text-md text-white">Je sessie is verlopen.</p>
            <p class="text-sm text-white">Log opnieuw in om verder te gaan.</p>
          </div>

          <h1 class="text-3xl font-bold mb-3">Inloggen</h1>
          <div v-if="!isIncorrect" class="mb-4">
            <p class="text-xs text-gray-600">Je moet eerst inloggen voordat je verder kan gaan.</p>
          </div>
          <form @submit.prevent="handleSubmit">
            <div class="mb-4">
              <label for="username" class="block text-sm font-medium text-gray-600">Gebruikersnaam</label>
              <input type="text" id="username" v-model="username" class="mt-1 p-2 w-full border rounded-md">
            </div>
            <div class="mb-4">
              <label for="password" class="block text-sm font-medium text-gray-600">Wachtwoord</label>
              <input type="password" id="password" v-model="password" class="mt-1 p-2 w-full border rounded-md">
            </div>
            <button type="submit" class="w-full p-2 bg-wbnl-blue hover:bg-wbnl-orange rounded-md text-white font-bold">
              Inloggen
            </button>
          </form>
          <div class="mt-4 flex justify-between">
            <router-link to="/auth/reset-password" class="text-sm text-gray-600 hover:text-gray-500 hover:underline">
              Wachtwoord vergeten?
            </router-link>
          </div>
          <button v-if="showEnvLogin"
                  class="mt-4 w-full p-2 bg-wbnl-orange hover:bg-wbnl-orange-light rounded-md text-white font-bold"
                  @click="loginWithEnv">[Developer lokaal inloggen]
          </button>
        </div>
      </div>
    </div>
  </MainEnvironment>
</template>

<style scoped>
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}
</style>
